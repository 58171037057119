<template>
  <div class="auth">
    <div class="auth-logo">
      <img width="188" src="/images/logo-h.jpg" alt="logo">
    </div>
    <div class="auth-form-block">
      <div class="auth-title mb-10"> {{ $t("auth.sign") }} <br/> {{ $t("auth.google") }}</div>
      <v-btn color="#4285F4" tile dark class="google mb-7" @click="authenticate('google')">
        <div class="icon-wrap">
          <img width="15" src="/images/google.png">
        </div>
        <span>
          {{ $t("auth.continue") }}
        </span>
      </v-btn>
      <p>
        {{ $t("auth.need") }}
        <a href="https://accounts.google.com/signup/v2/webcreateaccount?flowEntry=SignUp" target="_blank">
          <strong> {{ $t("auth.create") }} </strong>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Auth',
  metaInfo: {
    title: 'Auth'
  },
  data () {
    return {
      email: null,
      password: null
    };
  },
  methods: {
    ...mapActions(['setToken', 'socialLogin']),
    authenticate (provider) {
      this.$auth.authenticate(provider)
        .then(response => {
          response.redirect_uri = location.origin + '/auth';
          this.socialLogin({ provider, data: response })
            .then(() => {
              if (this.$store.getters.isAdmin) {
                this.$router.push('/admin');
              } else {
                this.$router.push('/');
              }
            });
        });
    }
  }
};
</script>
